.toggle-theme {
  display: flex;
  flex-direction: row;
}

.checkbox {
  height: 0;
  width: 0;
  margin: 0;
  visibility: hidden;
}

.slider-light {
  display: inline-block;
  cursor: pointer;
  width: 32px;
  height: 18px;
  background-color: rgba(40,42,53,0.12);
  display: block;
  border-radius: 100px;
  position: relative;
  text-align: right;
  margin: 0px 12px;
}

.slider-dark {
  @extend .slider-light;
  background-color: rgba(255, 255, 255, 0.2);
}

// switch slider
.slider-light:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  transition: 0.3s;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.05);
}

.slider-dark:after{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: inset 0 0 0 1px rgba(255,255,255,0.1);
}

.checkbox:checked + label {
  background: rgba(255, 255, 255, 0.07);
}

.checkbox:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
