@import url("./fonts.css");

.App {
  text-align: center;
  height: 100vh;
  background-color: rgba(240, 242, 246, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}

.App-dark {
  @extend .App;
  background-color: rgb(52, 55, 70);
}

.toggle-theme-container {
  display: flex;
  max-width: 1200px;
  justify-content: flex-end;
  position: absolute;
  top: 30px;
  right: 8%;
}

// centering
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.terminal-wrapper {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.terminal {
  height: 460px;
  width: 600px;
  border-radius: 12px;
  background-color: rgb(40, 42, 54);
  padding: 20px 25px;
  padding-top: 0;
  box-shadow: 0 30px 60px -12px rgba(0,0,0, 0.25), 0 18px 36px -18px rgba(33, 16, 95, 0.3),
    0 -12px 40px -8px rgba(0,0,0, 0.25);
  border: 1px solid rgba(0,0,0, .8);
  position: relative;
}

.terminal-dark {
  @extend .terminal;
  background-color: rgb(30, 32, 41);
  border: 1px solid rgba(33, 16, 95, 0.12);
  color: rgba(255,255,255, 0.7);
  font-weight: 200;
}

.terminal-header {
  color: rgba(255,255,255, 0.8);
  padding-bottom: 10px;
  padding-top: 15px;
  letter-spacing: 0.4px;
  z-index: 9999;
  background-color: rgba(0,0,0,0);
}

.terminal-header-title {
  margin: auto;
}

.content-wrapper {
  height: 93%;
  overflow: scroll;
  padding-bottom: 10px;
}

.content-wrapper::-webkit-scrollbar {
  display: none;
}

.terminal-content {
  text-align: left;
  color: rgb(253, 139, 247);
  font-family: "SF Mono", "PT Mono", "Lucida Console", "input mono", "anonymous pro", source-code-pro, Menlo, Consolas,
    monospace;
  font-size: 0.92rem;
  border-radius: 20px;
  padding: 10px 0;
  width: 98%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.sticky {
  align-self: flex-end;
  width: 100%;
}

.input-form {
  text-align: left;
}

.stack {
  display: inline-block;
  color: rgba(255,255,255, 0.7);
}

.user-input {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font-size: inherit;
  color: white;
  color: rgb(253, 139, 247);
  font-family: "SF Mono", "PT Mono", "Lucida Console", "input mono", "anonymous pro", source-code-pro, Menlo, Consolas,
    monospace;
  padding-left: 9px;
  padding-top: 7px;
  width: 60%;
}

.history-item {
  padding: 7px 0;
}

.history-input {
  color: rgb(253, 139, 247);
}

.history-output {
  color: white;
  line-height: 1.34em;
  padding-top: 4px;
  font-size: 0.91rem;
}

.output-item {
  display: inline-block;
  padding-right: 10px;
  color: white;
  line-height: 1.32em;
}

@media only screen and (max-width: 550px) {
  .terminal {
    width: 80%;
    height: 300px;
    font-size: 13px;
  }
  .terminal-content {
    font-size: 13px;
    height: 98%;
  }
}
