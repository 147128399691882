.help-container {
    position: absolute;
    right: 80px;
    bottom: 20px;
  }
  
  .help-button {
    font-weight: 400;
    color: rgba(33, 16, 95, 0.3);
    padding: 2px 8px;
    border: 1px solid rgba(33, 16, 95, 0.3);
    background-color: rgba(255,255,255, 0.6);
    box-shadow: 2px 2px 10px rgba(33, 16, 95, 0.1);
    border-radius: 100%;
    float: right;
    margin-right: 8px;
    &:hover {
      cursor: pointer;
    }
  }
  
  .help-button-dark {
    @extend .help-button;
    color: rgba(255,255,255, 0.4);
    background-color: rgb(52, 55, 71);
    border: 1px solid rgba(255,255,255, 0.2);
    box-shadow: 2px 2px 10px rgba(33, 16, 95, 0.4);
  }
  
  .help-content {
    z-index: 9999;
    background-color: #fff;
    padding: 10px;
    border: 1px solid rgba(33, 16, 95, 0.12);
    border-radius: 8px;
    box-shadow: 0 8px 14px -10px rgba(0, 0, 0, 0.2);
    max-width: 160px;
    margin-bottom: 15px;
    ul {
      list-style: white;
      text-align: left;
      padding-left: 9px;
      margin: 8px 0;
    }
    li {
      padding: 3px 0;
      line-height: 1.4em;
      list-style-type: none;
    }
    :after {
      position: absolute;
      bottom: 32px;
      left: 75%;
      content: "";
      border-top: solid 10px #fff;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }
    .monospace {
      font-family: "SF Mono", "PT Mono", "Lucida Console", "input mono", "anonymous pro", monospace;
      padding: 0 1px;
    }
  }
  
  .help-content-hidden {
    visibility: hidden;
  }
  
  .help-title {
    color: rgba(0, 0, 0, 0.7);
    padding-top: 3px;
    font-size: 14px;
  }
  
  .help-text-bullet {
    color: rgb(253, 139, 247);
    margin-right: 2px;
    font-size: 18px;
  }
  
  .help-text {
    font-size: 11.6px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    justify-items: start;
  }